<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Templates Fonts'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Templates Font Create'"
      :title="'Templates Font Create'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Submit</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :value="formDataToSend.name"
              :placeholder="'Name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>
          <p class="form-create__label">Url</p>
          <div class="input-container">
            <InputText
              :setValue="formDataToSend"
              :id="'url'"
              :label="'url'"
              :value="formDataToSend.url"
              :type="'text'"
              :placeholder="'SEO Name'"
              :error-messages="validationErrors['url']"
              isBordered
            />
          </div>
          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Submit</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import { seoTransform } from "~/helpers/seoTransform";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";

export default {
  name: "TemplatesFontCreate",
  metaInfo: {
    title: "Templates Font Create",
  },
  data() {
    return {
      formDataToSend: {
        name: "",
        url: "",
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
  },
  computed: {
    ...mapState("references", ["error", "loadingProcess", "validationErrors"]),
    getFirstLink() {
      return ROUTE.TEMPLATES_FONTS_LIST.replace(":page", 1);
    },
  },
  methods: {
    handleSubmit() {
      const data = {
        name: this.formDataToSend.name.length ? this.formDataToSend.name.trim() : "",
        url: this.formDataToSend.url.length ? this.formDataToSend.url.trim() : "",
      };
      this.$store.dispatch("references/createTemplatesFont", data);
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.TEMPLATES_FONTS_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
    },
  },
};
</script>

<style lang="scss" scoped></style>
